import { Scheme } from "../types";
import { API_BASE_URL } from "./base";

export async function fetchSchemeFromAPI(
  token?: string,
  id?: number
): Promise<Scheme | undefined> {
  const url = new URL(`/exercise_scheme.json`, API_BASE_URL);
  if (id) {
    url.searchParams.set("id", `${id}`);
  }
  const response = await fetch(url, {
    headers: token ? { "X-Authorization": `Bearer ${token}` } : {},
  });

  if (response) {
    return await response.json();
  } else {
    return undefined;
  }
}

export async function postSessionToAPI(
  token: string,
  session: object
): Promise<{ status: string; message?: string }> {
  const response = await fetch(`${API_BASE_URL}/post/session`, {
    method: "POST",
    headers: { "X-Authorization": `Bearer ${token}` },
    body: JSON.stringify({ session }),
  });

  if (response) {
    return await response.json();
  }
  throw new Error("no response");
}
