import { SaveOutlined } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useAuthStore } from "../state/authStore";
import { useExerciseStore } from "../state/exerciseStore";
import { useModalStore } from "../state/modalStore";
import { prefixZero, splitMsToTimeJS } from "../util/timeFunctions";

const DEFAULT_TIMER = "00:00";

export const Timer = () => {
  const isLoggedIn = useAuthStore((state) => !!state.token);
  const [startDate, endDate, toggleTimer, getDuration] = useExerciseStore(
    useShallow((state) => [
      state.startDate,
      state.endDate,
      state.toggleTimer,
      state.getDuration,
    ])
  );
  const [countdownText, setCountdownText] = useState(DEFAULT_TIMER);
  const openSaveSessionModal = useModalStore(
    (state) => state.openSaveSessionModal
  );

  useEffect(() => {
    if (!startDate) {
      return;
    }

    let intervalId: any = setInterval(() => {
      if (!startDate || endDate) {
        clearInterval(intervalId);
        intervalId = null;
      }
      const [hours, minutes, seconds] = splitMsToTimeJS(getDuration());
      setCountdownText(
        `${hours ? `${hours}:` : ""}${prefixZero(minutes)}:${prefixZero(
          seconds
        )}`
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, [startDate, endDate]);

  const clickSaveButton = () => {
    setCountdownText(DEFAULT_TIMER);
    // toggleTimer(); // NOTE: For debug purposes
    openSaveSessionModal();
  };

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      {startDate && <Typography variant="h2">{countdownText}</Typography>}
      {!endDate ? (
        <Button
          onClick={toggleTimer}
          variant="contained"
          color={startDate ? undefined : "error"}
        >
          {startDate ? "Finish" : "Start"}
        </Button>
      ) : (
        isLoggedIn && (
          <IconButton onClick={clickSaveButton}>
            <SaveOutlined />
          </IconButton>
        )
      )}
    </Stack>
  );
};
