import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { useExerciseStore } from "../state/exerciseStore";
import { useShallow } from "zustand/react/shallow";

type Props = {
  exerciseKey: string;
  unit: "kg" | "cm";
  defaultValue?: number;
};

export const BestEffortInput = ({ unit, exerciseKey, defaultValue }: Props) => {
  const [updateAchievedEffort, markExerciseCompleted] = useExerciseStore(
    useShallow((state) => [
      state.updateExerciseAchievedEfforts,
      state.markExerciseCompleted,
    ])
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateAchievedEffort(
      exerciseKey,
      event.target.value
        ? [parseFloat(event.target.value.replace(",", "."))]
        : [undefined]
    );
    if (event.target.value) {
      markExerciseCompleted(exerciseKey, true);
    }
  };

  const label = unit === "cm" ? "Beste hoogte" : "Beste gewicht";

  return (
    <Box sx={{ m: 1.5, ml: 2.5 }}>
      <FormControl variant="outlined">
        <InputLabel
          htmlFor={exerciseKey}
          sx={{
            "+.MuiInputBase-root .MuiInputAdornment-root": { opacity: 0 },
            "&.Mui-focused": {
              "+.MuiInputBase-root .MuiInputAdornment-root": { opacity: 1 },
            },
          }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          id={exerciseKey}
          endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
          inputProps={{
            "aria-label": `${exerciseKey}-best-effort`,
          }}
          onChange={handleChange}
          defaultValue={defaultValue}
          label={label}
        />
      </FormControl>
    </Box>
  );
};
